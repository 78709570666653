import React, { useEffect, useState } from "react";
import TextInfo from "@/bit/components/components.text";
import Button, { BUTTON_TYPE } from "@/bit/components/components.button";

const PageSelector = (props) => {
  const { handleChangeOrion } = props;
  const [multiSelected, setmultiSelected] = useState({});
  const [singleSelected, setsingleSelected] = useState({});
  const [singleValuesSelected, setsingleValuesSelected] = useState({});
  const multiKeys = Object.keys(props).filter((key) =>
    /^Multi-Select-\d+$/.test(key)
  );
  const multiValues = multiKeys.map((key) => props[key]);

  useEffect(() => {
    // Por parametros
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const modalidad = params.get("modalidad");
    if (modalidad) {
      multiValues.forEach((multi) => {
        Object.keys(multi).forEach((key) => {
          const single = multi[key];
          if (key.startsWith("Select") && single.url === modalidad) {
            const singleKeys = Object.keys(multi).filter((item) =>
              /^Select-\d+$/.test(item)
            );
            const singleValues = singleKeys.map((item) => multi[item]);
            setmultiSelected(multi);
            setsingleValuesSelected(singleValues);
            setsingleSelected(single);
            handleChangeOrion(single.orionAlias);
          }
        });
      });
    } else {
      const res = multiValues.find((key) => key.selected === true);
      setmultiSelected(res);

      if (res) {
        const singleKeys = Object.keys(res).filter((key) =>
          /^Select-\d+$/.test(key)
        );
        const singleValues = singleKeys.map((key) => res[key]);
        setsingleValuesSelected(singleValues);
        setsingleSelected(singleValues.find((key) => key.selected === true));
      }
    }
  }, []);

  return (
    <section className="multi-page-selector">
      <div className="multi-page-selector--title">
        {multiSelected && multiSelected["Text-0"] && (
          <TextInfo
            {...multiSelected["Text-0"]}
            kind="h"
            level="2"
            cyData="PageSelector Title"
          />
        )}
        {multiSelected && multiSelected["Text-1"] && (
          <TextInfo
            {...multiSelected["Text-1"]}
            kind="h"
            level="2"
            cyData="PageSelector Title"
          />
        )}
      </div>
      {multiValues && Array.isArray(multiValues) && multiValues.length > 0 && (
        <div className="multi-page-selector--row">
          {multiValues.map((select, index) => {
            const button = select["Button-1"];
            return (
              <div
                className="multi-page-selector--row_button"
                key={`multi-page-selector--${index}`}
              >
                <Button
                  type={
                    multiSelected && multiSelected.url === select.url
                      ? BUTTON_TYPE.PRIMARY_REVERSE
                      : BUTTON_TYPE.COLLAPSED
                  }
                  {...button}
                  href={select.url}
                  taggingInfo={{
                    componentName: "multi-page-selector",
                    componentVariation: "all",
                    componentContent: button.label,
                    componentProduct: button.label,
                    eventType: "click",
                  }}
                  cyData={`PageSelector-${index} Button`}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className="multi-page-selector--title">
        {singleSelected && singleSelected["Text-0"] && (
          <TextInfo
            {...singleSelected["Text-0"]}
            kind="h"
            level="2"
            cyData="PageSelector Title"
          />
        )}
        {singleSelected && singleSelected["Text-1"] && (
          <TextInfo
            {...singleSelected["Text-1"]}
            kind="h"
            level="2"
            cyData="PageSelector Title"
          />
        )}
      </div>
      {singleValuesSelected &&
        Array.isArray(singleValuesSelected) &&
        singleValuesSelected.length > 0 && (
          <div className="multi-page-selector--row">
            {singleValuesSelected.map((select, index) => {
              const button = select["Button-1"];
              return (
                <div
                  className="multi-page-selector--row_button"
                  key={`multi-page-selector--${index}`}
                >
                  <Button
                    type={
                      singleSelected &&
                      singleSelected.orionAlias === select.orionAlias
                        ? BUTTON_TYPE.PRIMARY_REVERSE
                        : BUTTON_TYPE.COLLAPSED
                    }
                    {...button}
                    onClick={() => {
                      setsingleSelected(select);
                      handleChangeOrion(select.orionAlias);
                    }}
                    taggingInfo={{
                      componentName: "multi-page-selector",
                      componentVariation: "all",
                      componentContent: button.label,
                      componentProduct: button.label,
                      eventType: "click",
                    }}
                    cyData={`PageSelector-${index} Button`}
                  />
                </div>
              );
            })}
          </div>
        )}
      {/* INJECT_STYLED_JSX_TAG */}
    </section>
  );
};

PageSelector.propTypes = {};

PageSelector.defaultProps = {};

export default PageSelector;
