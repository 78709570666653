import React, { useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import { actions as mainActions } from "@/bit/reducers/reducers.main-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import { whichAnimationEvent } from "./utils";
import PriceModule from "./price-module";
import ActionCallModule from "./action-call-module";
import LegalModule from "./legal-module";
import ActionCallTwoModule from "./action-call-two-module";

function BuyBox(props) {
  const dispatch = useDispatch();
  const { zone, cpModalClosed, refreshZone, logged } = useSelector(
    (state) => state.main
  );
  const [ firstHitZone, setFirstHitZone ] = useState(true);

  const product = useMemo(
    () =>
      props['Product-2'] && zone === 'ZR'
        ? props['Product-2']
        : props['Product-1'],
    [zone && cpModalClosed, props['Product-1'], props['Product-2']]
  );

  let skeletonClassName = zone || !props['Product-2'] ? 'noanimated' : '';

  if(props.skeletonClassName) {
    skeletonClassName = 'skeleton';
  }

  const buttonHref = product["Button-1"]?.urlProdAncla
    ? product["Button-1"]?.urlProdAncla
    : product["Button-1"]?.href
    ? product["Button-1"]?.href
    : logged
    ? product["Button-1"]?.urlLinkclient
    : product["Button-1"]?.urlLinknoclient;


  const checkProductExistZone = () => {
    if (((!props['Product-2'] && zone === 'ZR') || (!props['Product-1'] && zone === 'ZL')) && props.modalContentNoAvailable) {
      const transitionEvent = whichAnimationEvent();
      const element = document.getElementById('animationReference');

      if (transitionEvent && element) {
        element.addEventListener(transitionEvent, () => {
          setTimeout(() => {
            showModalProductNoExistHanlder();
          }, 100);
        });
      } else {
        showModalProductNoExistHanlder();
      }
      return false;
    } else {
      return true;
    }
  };
  const showModalProductNoExistHanlder = () => {
    const BuyBoxModal = dynamic(() => import('./modals/product-no-available'));
    const device = getDeviceKindDependsOnViewPort();

    dispatch(
      modalActions.addModal({
        id: 'productNoAvailable',
        Element2Show: BuyBoxModal,
        modalProps: {
          contentPosition:
            device !== 'MOBILE' ? 'center--center' : 'center--bottom',
          opacityLayoutColor: 'blur',
          inSceneEffect: 'fadeIn',
          contentInsideContainer: false,
          outSceneEffect: 'fadeOut',
          closeByClickingOutside: false,
          scrollAllowed: false,
          historyBackOnClose: false
        },
        elementProps: {...props.modalContentNoAvailable},
      })
    );
  }

  const showModalLegalHandler = () => {
    const LegalModal = dynamic(() => import('./modals/legal'));

    dispatch(
      modalActions.addModal({
        id: 'legal-modal',
        Element2Show: LegalModal,
        modalProps: {
          closeIdByClickingOutside: '',
          contentPosition: 'right--top',
          opacityLayoutColor: 'blur',
          inSceneEffect: 'fadeIn',
          contentInsideContainer: false,
          outSceneEffect: 'fadeOut',
          closeByClickingOutside: true,
          scrollAllowed: false,
          createAnchor: false,
        },
        elementProps: {
          title: product['Legal-1']?.['Text-1'] || '',
          description: product['Legal-1']?.['Text-2'] || '',
        },
      })
    );
  };

  const changeZoneModalHandler = () => {
    const BuyBoxModal = dynamic(() => import('./modals/zone-change'));
    const device = getDeviceKindDependsOnViewPort();

    dispatch(
      modalActions.addModal({
        id: 'cnmc',
        Element2Show: BuyBoxModal,
        modalProps: {
          contentPosition:
            device !== 'MOBILE' ? 'center--center' : 'center--bottom',
          opacityLayoutColor: 'blur',
          inSceneEffect: 'fadeIn',
          contentInsideContainer: false,
          outSceneEffect: 'fadeOut',
          closeByClickingOutside: true,
          scrollAllowed: false,
        },
        elementProps: {},
      })
    );
  };

  const onChangeZone = () => {
    const transitionEvent = whichAnimationEvent();
    const element = document.getElementById('animationReference');

    if (transitionEvent) {
      element.addEventListener(transitionEvent, () => {
        setTimeout(() => {
          changeZoneModalHandler();
        }, 100);
      });
    }
  };
  
  useEffect(() => {
    if(firstHitZone && zone !== ''){
      setFirstHitZone(false);
      checkProductExistZone();
    }
  }, [zone]);

  useEffect(() => {
    if(refreshZone && checkProductExistZone()) {
      if (refreshZone && props['Product-2']) {
        onChangeZone();
      }
    }

    dispatch(mainActions.setZoneRefreshFlag(false));
  }, [product && refreshZone && cpModalClosed]);

  return (
    <div className="buy-box">
      <div className="buy-box-content">
        <PriceModule
          prePriceText={product['Text-0']}
          topLabel={product['Label-1']}
          mainPrice={product['Price-2']}
          secondaryPrice={product['Price-1']}
          postPriceText={product['Text-1']}
          secondaryText={product['Text-2']}
          skeletonClassName={skeletonClassName}
        />
        <ActionCallModule
          simplePhone={props['simplePhone']}
          button={{ ...product['Button-1'], href: buttonHref }}
          auxButton={product['Button-3']}
          textMobileOne={product['TextMobile-1']}
          textMobileTwo={product['TextMobile-2']}
          skeletonClassName={skeletonClassName}
          C2C={props['simplePhone']?.['Text-3']}
        />
      </div>

      <LegalModule
        button={product['Button-2']}
        onClick={showModalLegalHandler}
        text={product['Text-4']}
        skeletonClassName={skeletonClassName}
      />

      <ActionCallTwoModule
        simplePhone={props['simplePhone']}
        product={product['Phone-Module']}
      />
      <style jsx global>{`.buy-box {
  position: relative;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 8px;
  padding: 1rem;

  .buy-box-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    .call-to-action {
      width: 100%;

      display: flex;

      flex-flow: column-reverse;
      margin-top: 1.5rem;
      align-items: flex-start;
      gap: 1rem;

      .simple-phone-component {
        flex: 1;
        width: 100%;

        .mainPhoneCTA {
          .mobile {
            display: inline-block;
            margin-right: 0.4rem;
          }
          .mobile-button-full {
            display: block;
          }

          .action-container {
            display: inline;
          }
        }
      }

      .call-to-action-text-mobile-one {
        display: block;
        width: 100%;
      }
      .call-to-action--button-row {
        display: flex;
        width: 100%;
        gap: 8px;
        flex-direction: column;
      }

      .principal-button {
        width: 100%;
      }

      .simple-phone-component {
        .buttonx {
          width: 100%;
        }
      }
    }

    .text.label {
      display: inline;
      margin-bottom: 0.5rem;
    }

    .buy-box-content-price {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .text-claim {
        margin-bottom: 0.8rem;
      }
    }
    .buy-box-content-price_flex {
      display: flex;
      gap: 0.5rem;
    }
    .buy-box-content-text {
      display: flex;
      gap: 0.5rem;
    }
    .buy-box-content-button {
      width: 100%;
    }
    .buy-box-content-button-full {
      margin-top: 0;
      max-width: 22rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .skeleton-legals {
    display: flex;
    align-items: center;
  }
  .buy-box-legal {
    .legal-button {
      justify-content: flex-start;
      padding: 0;
    }
  }
  hr.separator {
    color: #dddddd;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .buy-box-legal {
    a {
      display: block;
      text-align: left;
      padding: 0;
    }
  }
}

@media screen and (min-width: 868px) {
  .buy-box {
    .buy-box-content {
      .call-to-action {
        flex-flow: row;
        gap: 0;

        .call-to-action-text-mobile-one {
          display: none;
        }
        
      .call-to-action--button-row {
        flex-direction: row;
      }

        .principal-button {
          width: 178px;
        }

        .simple-phone-component {
          .buttonx {
            width: 178px;
            margin-right: 0.5rem;
          }

          .mainPhoneCTA {
            .mobile {
              display: none;
            }

            .mobile-button-full {
              display: none;
            }
          }
        }

        .buy-box-content-button {
          flex: 1;
        }
      }
    }

    hr.separator {
      color: #dddddd;
      margin-top: 25px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .buy-box {
    .buy-box-content {
      flex-direction: row;
      align-items: flex-end;

      .call-to-action {
        flex-flow: row;
        justify-content: flex-end;
        &.phone-below-price {
          flex: auto;
          gap: 1rem;
        }

        &.phone-normal {
          flex: 0;
        }

        .simple-phone-component {
          min-width: 200px;
          flex: 0;
        }

        .buy-box-content-button {
          flex: 0.5rem;
          margin-top: 0;

          min-width: 10.8rem;
        }
      }
    }
    hr.separator {
      color: #dddddd;
      margin-top: 25px;
    }
  }
}
`}</style>
    </div>
  );
};

export default BuyBox;
